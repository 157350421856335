import {DtoOktaConfigurationAbstraction} from "./dto.okta.configuration.abstraction";
import {IsIn, IsInt, IsPositive, IsString} from "class-validator";

export class DtoOktaConfigurationDeployRequest extends DtoOktaConfigurationAbstraction {

    @IsInt()
    @IsPositive()
    configurationVersion: number;

    @IsInt()
    @IsPositive()
    deploymentNumber: number;

    @IsString()
    @IsIn(['COMPLETE', 'DEPLOYING'])
    deploymentStatus: string;

    async $send(version: string) {
        return await this.$http('post', `deployments/${version}`);
    }
}
