































import {get} from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Title from "@/components/Title.vue";
import ContentBox from "@/components/ContentBox.vue";
import VueToggles from 'vue-toggles';
import {DtoOktaConfigurationDeployRequest} from "@/classes/dtos/okta/configuration/dto.okta.configuration.deploy.request";
import {DtoOktaConfigurationDeleteRequest} from "@/classes/dtos/okta/configuration/dto.okta.configuration.delete.request";

@Component({
  components: { Title, ContentBox, VueToggles }
})
export default class Version extends Vue {

  @Prop({ type: Object, default: () => ({}) })
  record: Record<any, any>;

  @Prop({ type: Boolean, default: false })
  disabled: boolean;

  get title(): string {
    let string = `Version ${this.record.version}`;
    let status: string[] = [];
    if (this.opened) status.push('opened');
    if (this.active) status.push('deployed');
    if (status.length) string += ` (${status.join(', ')})`
    return string;
  }

  get opened() {
    return this.record.version === this.$store.getters["configuration/version"];
  }

  get active(): boolean {
    return this.record.active;
  }

  get deployed(): string {
    return this.record.deployStatus && this.record.deployEndDate;
  }

  get status(): string {
    return this.deployed
      ? `Deploy date: ${this.deployed}`
      : 'Version has not been deployed'
  }

  get removalDisabled() {
    return Boolean(this.deployed);
  }

  get removeDisabledTooltip() {
    if (this.disabled) return null;
    if (this.opened) return 'Cannot remove opened version';
    if (this.removalDisabled) return 'Cannot remove version that was deployed in the past';
    return null;
  }

  async deploy() {
    const version = this.record.version;
    const request = await new DtoOktaConfigurationDeployRequest().$send(version);
    const error = get(request, '$error.response.data.error_message');

    let title;
    if (error) {
      title = 'Backlog'
      this.$toast.error(error);
    }
    else {
      title = `Version #${version}`;
      this.$toast.success(`DEPLOY SCHEDULED: ${title}`);
    }
    const toast = this.$toast.open({
      type: 'info',
      message: '',
      duration: 0,
      dismissible: false
    });
    this.$emit('schedule', { version, toast, title });
  }

  async open() {
    const {version} = this.record;
    await this.$store.dispatch("configuration/fetchSpecific", version);
  }

  async remove() {
    if (this.removalDisabled) return;
    this.$store.state.versions.disableControls = true;
    const request = await new DtoOktaConfigurationDeleteRequest().$send(this.record.version);
    if (request.$error) {
      const message = get(request, '$error.response.data.error_message');
      this.$store.state.versions.disableControls = false;
      this.$toast.error(message);
      return null;
    }
    await this.$store.dispatch("versions/updateVersions");
    this.$store.state.versions.disableControls = false;
    this.$toast.success(`VERSION ${this.record.version} DELETED`);
  }

  removeMetadata(text: string) {
    return text
      .replace(/^(.)+(by)/, '')
      .replace(/[@].+/, '')
      .trim();
  }

}

